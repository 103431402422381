import { set } from '@redwoodjs/forms'
import { Box, Image, Text, TextInput } from 'grommet'
import { useEffect, useRef, useState } from 'react'

const notes = [
  { note: 'C', ref: 'rh_c1.png' },
  { note: 'D', ref: 'rh_d1.png' },
  { note: 'E', ref: 'rh_e1.png' },
  { note: 'F', ref: 'rh_f1.png' },
  { note: 'G', ref: 'rh_g1.png' },
  { note: 'A', ref: 'rh_a1.png' },
  { note: 'B', ref: 'rh_b1.png' },
  { note: 'C', ref: 'rh_c2.png' },
  { note: 'D', ref: 'rh_d2.png' },
  { note: 'E', ref: 'rh_e2.png' },
  { note: 'F', ref: 'rh_f2.png' },
  { note: 'G', ref: 'rh_g2.png' },
  { note: 'A', ref: 'rh_a2.png' },
  { note: 'E', ref: 'lh_e1.png' },
  { note: 'F', ref: 'lh_f1.png' },
  { note: 'G', ref: 'lh_g1.png' },
  { note: 'A', ref: 'lh_a1.png' },
  { note: 'B', ref: 'lh_b1.png' },
  { note: 'C', ref: 'lh_c1.png' },
  { note: 'D', ref: 'lh_d1.png' },
  { note: 'E', ref: 'lh_e2.png' },
  { note: 'F', ref: 'lh_f2.png' },
  { note: 'G', ref: 'lh_g2.png' },
  { note: 'A', ref: 'lh_a2.png' },
  { note: 'B', ref: 'lh_b2.png' },
  { note: 'C', ref: 'lh_c2.png' },
]

const NoteQuiz = () => {
  const [currentNote, setCurrentNote] = useState({ note: '', ref: '' })
  const [currentGuess, setCurrentGuess] = useState('')
  const textInRef = useRef(null)

  useEffect(() => {
    setRandomNote()
    textInRef?.current?.focus()
  }, [])

  const setRandomNote = () => {
    const i = Math.floor(Math.random() * notes.length)
    setCurrentNote(notes[i])
  }

  const onGuessInput = async (e: any) => {
    setCurrentGuess(e.target.value.toUpperCase())
    textInRef.current.disabled = true
    await new Promise((f) => setTimeout(f, 1500))
    setCurrentGuess('')
    setRandomNote()
    textInRef.current.disabled = false
    textInRef?.current?.focus()
  }

  const isGuessCorrect = (): boolean => {
    return currentGuess == currentNote.note
  }

  return (
    <Box align="center" direction="column" justify="start">
      <Box fill={false} align="center" height="xxsmall">
        <Text color={isGuessCorrect() ? 'green' : 'red'}>
          {currentGuess &&
            (isGuessCorrect() ? 'Correct!' : `Wrong - ${currentNote.note}`)}
        </Text>
      </Box>
      <Box height="small" width="small">
        <Image
          fill="horizontal"
          fit="contain"
          src={`/images/${currentNote.ref}`}
        />
      </Box>
      <Box width="xxsmall" align="center" border={true}>
        <TextInput
          textAlign="center"
          value={currentGuess}
          onChange={onGuessInput}
          ref={textInRef}
        />
      </Box>
    </Box>
  )
}

export default NoteQuiz
