import { Box, Button, Main, Text } from 'grommet'
import { useState } from 'react'
import NoteQuiz from 'src/components/NoteQuiz/NoteQuiz'

const NoteDrillerPage = () => {
  const [quizStarted, setQuizStarted] = useState(false)
  const onStartBtnClick = (event: any) => {
    console.log('event: ', event)
    setQuizStarted(true)
  }

  return (
    <Main pad="large">
      <Box fill align="center">
        {!quizStarted ? (
          <>
            <Box pad={{ bottom: 'medium' }}>
              <Text size="xxlarge">Note-Driller</Text>
            </Box>
            <Button
              label="Start"
              primary
              size="large"
              onClick={onStartBtnClick}
            />
          </>
        ) : (
          <>
            <NoteQuiz />
          </>
        )}
      </Box>
    </Main>
  )
}

export default NoteDrillerPage
