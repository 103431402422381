import { Anchor, Box, Header, Nav, Text } from 'grommet'

type MainLayoutProps = {
  children?: React.ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      <Header background="dark-1" pad="small">
        <Box direction="row" align="center" gap="small">
          <Anchor href="/">
            <Text size="large" color="white">
              Music Trainer
            </Text>
          </Anchor>
        </Box>
        <Nav direction="row">
          <Anchor href="/note-driller" label="Note Driller" />
          <Anchor href="/key-driller" label="Keys Driller" />
        </Nav>
      </Header>
      <main>{children}</main>
    </>
  )
}

export default MainLayout
